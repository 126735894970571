import $ from 'jquery'

function ajaxLoadPosts(page = 1, categories = '', post_type = 'post', posts_limit = 6, num_columns = 3) {
  const data = {
    page: page,
    categories: categories,
    post_type: post_type,
    posts_limit: posts_limit,
    num_columns: num_columns,
    action: 'ajax_posts_filter'
  };

  $.ajax({
    url: php_vars.ajax_url,
    type: "POST",
    dataType: 'html',
    data,
    beforeSend: function () {
      $('html, body').animate({
        scrollTop: $('.js-ajax-posts').offset().top - 200
      }, 100);
      $('body').find('.js-ajax-posts').addClass('loading');
    },
    success: function (htmlResponse) {
      if ( htmlResponse == "" || htmlResponse == null ) {
        $('body').find('.js-ajax-posts').html('<p class="no-results">No results found, please try a different combination of filters.</p>');
      }
      else {
        $('body').find('.js-ajax-posts').html(htmlResponse);
      }
    },
    error: function() {
      $('body').find('.js-ajax-posts').html('<p class="no-results">No results found.</p>');
    },
    complete: function () {
      $('body').find('.js-ajax-posts').removeClass('loading');
    }
  });
}

function filterPosts() {
  if ($('.js-ajax-posts').length ) {
    let $postsLimit = $('.js-ajax-posts').data('posts-limit');
    let $numCols = $('.js-ajax-posts').data('num-cols');
    let $postType = $('.js-ajax-posts').data('post-type').split(',');
    let $categories = [];
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('cat_id')) {
        $categories = [];
        $categories.push(searchParams.get('cat_id'));
        $('.js-ajax-categories li').removeClass('current-cat');
        $('.js-ajax-categories a[data-category="' + searchParams.get('cat_id') + '"]').parent().addClass('current-cat');
        ajaxLoadPosts(1, $categories, $postType, $postsLimit, $numCols);
      }
    }
    $('.js-ajax-selects select').on('change', function () {
      $categories = [];
      $('.js-ajax-selects select').each(function () {
        if ($(this).val() != 'all') {
          $categories.push($(this).val());
        }
      });
      ajaxLoadPosts(1, $categories, $postType, $postsLimit, $numCols);
    });
    $(document).on('click', '.js-ajax-pagination a', function (event) {
      if ('.js-ajax-selects'.length) {
        $categories = [];
        $('.js-ajax-selects select').each(function () {
          if ($(this).val() != 'all') {
            $categories.push($(this).val());
          }
        });
        if ($('.js-ajax-categories .current-cat').length) {
          $categories.push($('.js-ajax-categories .current-cat a').data('category'));
        }
      }
      let $page = parseInt($(this).attr('href').replace(/\D/g, ''));
      event.preventDefault();
      ajaxLoadPosts($page, $categories, $postType, $postsLimit, $numCols);
    });
    $(document).on('click', '.js-ajax-categories a', function (event) {
      event.preventDefault();
      $categories = [];
      $('.js-ajax-categories li').removeClass('current-cat');
      $(this).parent().addClass('current-cat');
      $categories.push($(this).data('category'));
      ajaxLoadPosts(1, $categories, $postType, $postsLimit, $numCols);
    });
    $(document).on('click', '.js-filters-clear', function (event) {
      event.preventDefault();
      $('.js-ajax-selects select').val('all');
      $categories = [];
      ajaxLoadPosts(1, $categories, $postType, $postsLimit, $numCols);
    });
  }
}


export default function initFilterPosts(){
  $(document).ready(filterPosts)
}
