import $ from 'jquery'

let videoIsPlaying = true;

function toggleVideo(e) {
  const toggleButton = e.target;
  const targetVideo = e.target.nextSibling;

  if (!videoIsPlaying) {
    targetVideo.play();
    videoIsPlaying = true;
    toggleButton.classList.add('playing');
  } else {
    targetVideo.pause();
    videoIsPlaying = false;
    toggleButton.classList.remove('playing');
  }
}

export default function initVideo() {
  $(document).on('click', '.js-video', toggleVideo)
}
