import $ from 'jquery'

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function init(){
  var hasCookie = getCookie('announcement-cookie');
  if(!hasCookie) $(".announcement-banner").slideDown();

  $('.js-banner-close').on('click', function() {
    $(".announcement-banner").slideUp().removeClass('open');
    setCookie('announcement-cookie', 'true', 1);
  });
}

export default function initAnnouncements(){
  $(document).ready( init )
}
