import $ from 'jquery'

const $doc = $(document)

function toggleTabItem() {
  const targetContent = $(this).data('target')
  let el = $(this);

  el.parent().parent().find('.js-tab-item').removeClass('active').attr('aria-selected', 'false').attr('tabindex', '-1')
  el.parent().parent().find('.js-tab-content').removeClass('active').attr('hidden', 'true')

  el.addClass('active').attr('aria-selected', 'true').removeAttr('tabindex')
  $(`#${targetContent}`).addClass('active').removeAttr('hidden')
}

export default function initTabs(){
  $doc.on('click', '.js-tab-item', toggleTabItem )
}
