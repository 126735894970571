import $ from 'jquery'

export default function initImgWraps(){
  if ( ( '.basic-text .wysiwyg-editor' ).length ) {
    $('.basic-text .wysiwyg-editor img' ).each(function () {
      let classList = $(this).attr('class');
      $(this).wrap('<div class="img-wrap '+classList+'"><div class="img-wrap__inner"></div></div>');
    });
  }
}
