import $ from 'jquery'

function initMapSvg(){
  console.log('Interactive Map found');

  if($(".svg-text__wysiwyg").length){
    var highlightClass = 'svg-text__highlight u-color-'+$(".svg-text__wysiwyg").attr('highlight-color');
  }

  $(".mapInteractivePath").on('mouseenter', function(){
    var mapPathId = $(this).attr('id');
    var textSection = $('.mapInteractiveText[mapPathId='+mapPathId+']');

    $('.mapInteractivePath').removeClass('svg-text__map-visible');
    $('.mapInteractiveText').removeClass(highlightClass);
    $('.mapInteractiveText').addClass('svg-text__text-shade');
    $('.mapInteractivePath').addClass('svg-text__map-shade');

    $(this).addClass('svg-text__map-visible');
    $(textSection).addClass(highlightClass);
  })

  $(".mapInteractivePath").on('mouseleave', function(){
    $('.mapInteractiveText').removeClass(highlightClass);
    $('.mapInteractiveText').removeClass('svg-text__text-shade');
    $('.mapInteractivePath').removeClass('svg-text__map-shade');
  })

  $(".mapInteractiveText").on('mouseenter', function(){
    var mapPathId = $(this).attr('mapPathId');
    $('.mapInteractiveText').removeClass(highlightClass);
    $(this).addClass(highlightClass);

    $('.mapInteractivePath').removeClass('svg-text__map-visible');
    $('.mapInteractivePath').addClass('svg-text__map-shade');
    $('.mapInteractivePath[id='+mapPathId+']').addClass('svg-text__map-visible');
  })

  $(".mapInteractiveText").on('mouseleave', function(){
    $('.mapInteractivePath').removeClass('svg-text__map-visible svg-text__map-shade');
    $('.mapInteractiveText').removeClass(highlightClass).removeClass('svg-text__text-shade');
  })

}

export default function initInteractiveSvg(){
  $(document).ready( initMapSvg )
}
