import $ from 'jquery'

function init(){
  let a = 0;
  const counters = $('.js-count');
  if ( $( 'body' ).first().hasClass( 'home' ) ) {
    $(window).scroll(function() {
      const oTop = counters.offset().top - window.innerHeight;
      if (a == 0 && $(window).scrollTop() > oTop) {
        counters.each(function () {
          $(this).prop('Counter',0).animate({
            Counter: $(this).text()
          },
          {
            duration: 2000,
            easing: 'swing',
            step: function (now) {
              $(this).text(Math.ceil(now).toLocaleString('en'));
            }
          });
        });
        a = 1;
      }
    });
  }
}

export default function initCounters(){
  $(document).ready( init )
}
