import $ from 'jquery'

function fillInputs() {
  $(document).on('click', '.js-fillinputs', function (event) {
    event.preventDefault();
    let el = $(this);
    let firstName = el.siblings('.js-input-firstname').val();
    let lastName = el.siblings('.js-input-lastname').val();
    let email = el.siblings('.js-input-email').val();
    if ($('#gform_2').length) {
      $('#input_2_2_3').val(firstName);
      $('#input_2_2_6').val(lastName);
      $('#input_2_1').val(email);
    }
    if ($('#gform_4').length) {
      $('#input_4_2_3').val(firstName);
      $('#input_4_2_6').val(lastName);
      $('#input_4_1').val(email);
    }
  });
}

export default function initFillInputs(){
  fillInputs();
}
